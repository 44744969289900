"use client";

import React, { useEffect, useRef, useState } from "react";
import styles from "./CommunityMap.module.scss";
import H2 from "@/components/H2";
import CircleBackground from "@/components/icons/CircleBackground";
import Map, {
  GeolocateControl,
  MapProvider,
  Marker,
  NavigationControl,
  useMap,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Button } from "@/components/actions/Button";
import AnimateIn from "@/components/AnimateIn";
import For from "@/components/For";
import If from "@/components/If";
import Link from "next/link";
import SelectInput from "@/components/form/Select/Select";
import { TextInput } from "@/components/form/TextInput";
import { useRouter } from "next/navigation";
import useSupercluster from "use-supercluster";

const mapboxToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

/**
 *
 * CommunityMap
 *
 */
const CommunityMap = ({
  markers = [],
  residences = [],
  isInterior = false,
  ...props
}) => {
  return (
    <MapProvider>
      <TheMap
        markers={markers}
        residences={residences}
        {...props}
        isInterior={isInterior}
      />
    </MapProvider>
  );
};

const TheMap = ({
  markers = [],
  residences = [],
  isInterior = false,
  ...props
}) => {
  const router = useRouter();
  const [activeMarkers, setActiveMarkers] = useState(markers);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [zoom, setZoom] = useState(7);
  const [imageWidth, setImageWidth] = useState(70);
  const [showOverlay, setShowOverlay] = useState(false);
  const mapRef = useRef(null);
  const [canScrollZoom, setCanScrollZoom] = useState(false);
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState("");
  const [viewport, setViewport] = useState({
    latitude: 43.065402754145495,
    longitude: -89.44264292318853,
    zoom: 7,
  });
  const [points, setPoints] = useState([]);
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      const _points = (activeMarkers || markers).map((marker) => ({
        type: "Feature",
        properties: { cluster: false, ...marker },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(
              marker?.locationDetails?.location?.lon || marker?.location?.lon
            ),
            parseFloat(
              marker?.locationDetails?.location?.lat || marker?.location?.lat
            ),
          ],
        },
      }));
      const _bounds = mapRef.current
        ? mapRef.current.getMap().getBounds().toArray().flat()
        : null;

      // console.log("Bounds", _bounds);

      setPoints(_points);
      setBounds(_bounds);
    }, 300);
  }, [activeMarkers, viewport]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 60, maxZoom: 20 },
  });

  const zoomToSelectedLoc = (e, location, index) => {
    if (window.innerWidth < 1024) {
      router.push(`/communities/${location.slug}`);
      return;
    }
    // stop event bubble-up which triggers unnecessary events
    e.stopPropagation();
    console.log("Location", location);
    setSelectedMarker({ location, index });
    // mapRef.current.flyTo({
    //   center: [location?.location.lon, location?.location.lat],
    // });
    setShowOverlay(true);
  };

  const zoomToSelectedCluster = (e, cluster) => {
    console.log("Cluster", cluster);
    mapRef.current.flyTo({
      center: [...cluster?.geometry?.coordinates],
      zoom: supercluster.getClusterExpansionZoom(cluster.id) + 2,
    });
    // const expansionZoom = Math.min(
    //   supercluster.getClusterExpansionZoom(cluster.id),
    //   20
    // );

    // setViewport({
    //   ...viewport,
    //   zoom: viewport.zoom + 5,
    // });
  };

  const getTheme = (theme: string) => {
    if (theme === "Green") return "bg-green";
    if (theme === "Purple") return "bg-campus-purple";
    if (theme === "Blue") return "bg-dark-blue";
    return "bg-dark-blue";
  };

  return (
    <AnimateIn
      as="section"
      className={`${styles["community-map"]} lg:rounded overflow-hidden bg-light-teal-bg flex flex-col lg:grid grid-cols-12 gap-8 content lg:mt-32`}
      {...props}
    >
      <div className="col-span-12 md:col-span-4 stack  relative">
        <CircleBackground className="absolute top-0 right-[-110%] z-0 h-full" />
        <div className="relative z-10 stack gap-14 h-full pb-20">
          <If condition={!isInterior}>
            <div>
              <p className="text-dark-blue">Start your journey</p>
              <H2>Find your community</H2>
            </div>

            <p>
              Welcome to a whole new way to experience senior living. Welcome to
              Illuminus. Discover the vibrant, one-of-a-kind communities of
              Illuminus.
            </p>
            <Button link="/communities" className="w-max">
              Find your community
            </Button>
          </If>

          <If condition={isInterior}>
            <div>
              <p className="text-dark-blue">Start your journey</p>
              <H2>Our communities</H2>
            </div>

            <form
              className="stack gap-12"
              onSubmit={(ev) => {
                ev.preventDefault();
                let newMarkers = [];

                mapRef.current.flyTo({
                  center: [-89.44264292318853, 43.065402754145495],
                  zoom: 7,
                });

                if (filter === "all") {
                  setActiveMarkers(markers);
                  return;
                }

                if (filter === "affordable-housing") {
                  setActiveMarkers(residences);
                  return;
                }

                newMarkers = markers.filter((marker) =>
                  marker?.contentfulMetadata?.tags.some((tag) =>
                    filter.includes(tag.name)
                  )
                );
                if (search) {
                  newMarkers = newMarkers.filter((marker) =>
                    marker.title.toLowerCase().includes(search.toLowerCase())
                  );
                }
                setActiveMarkers(newMarkers);
              }}
            >
              <SelectInput
                placeholder="Lifestyle Option"
                name="lifestyle-option"
                options={[
                  { value: "all", label: "All" },
                  { value: "Independent Living", label: "Independent Living" },
                  {
                    value: "Assisted Living",
                    label: "Assisted Living / Memory Care",
                  },
                  {
                    value: "Skilled Nursing / Rehab",
                    label: "Skilled Nursing / Rehab",
                  },
                  { value: "affordable-housing", label: "Affordable Housing" },
                ]}
                onChange={(e) => setFilter(e)}
              />
              <TextInput
                placeholder="Search locations"
                name="search"
                onChange={(ev) => setSearch(ev.target.value)}
              />

              <div className="flex gap-8">
                <Button type="submit" className="w-max">
                  Search
                </Button>
                <button
                  type="button"
                  onClick={() => {
                    setFilter("all");
                    setSearch("");
                    setActiveMarkers(markers);
                    mapRef.current.flyTo({
                      center: [-89.44264292318853, 43.065402754145495],
                      zoom: 7,
                    });
                  }}
                  className="w-max"
                >
                  Reset
                </button>
              </div>
            </form>
          </If>
        </div>
      </div>
      <div className="col-span-12 md:col-span-8 lg:rounded h-full aspect-[900/684] overflow-hidden relative">
        <Map
          mapboxAccessToken={mapboxToken}
          mapStyle="mapbox://styles/bairdlb/clvekfrja028a01ph1ueu8xsc"
          // dragPan={false}
          scrollZoom={false}
          onZoom={(e) => {
            setViewport(e.viewState);
            setZoom(e.viewState.zoom);
            setImageWidth(
              e.viewState.zoom > 10
                ? e.viewState.zoom * 15
                : e.viewState.zoom * 15
            );
          }}
          onMove={(e) => {
            setViewport(e.viewState);
          }}
          initialViewState={{
            latitude: 43.065402754145495,
            longitude: -89.44264292318853,
            zoom: 7,
          }}
          maxZoom={20}
          ref={mapRef}
          reuseMaps
          onClick={() => setCanScrollZoom(true)}
        >
          <GeolocateControl position="top-left" />
          <NavigationControl position="bottom-right" />

          {/* {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (isCluster) {
              console.log("Cluster", cluster, points);
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker cursor-pointer z-[100000000]"
                    style={{
                      width: `${20 + (pointCount / points.length) * 40}px`,
                      height: `${20 + (pointCount / points.length) * 40}px`,
                    }}
                    onClick={(e) => {
                      zoomToSelectedCluster(e, cluster);
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
          })} */}

          {clusters.map((cluster, index) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker cursor-pointer flex flex-col"
                    onClick={(e) => {
                      zoomToSelectedCluster(e, cluster);
                      // const expansionZoom = Math.min(
                      //   supercluster.getClusterExpansionZoom(cluster.id),
                      //   20
                      // );

                      // setViewport({
                      //   ...viewport,
                      //   latitude,
                      //   longitude,
                      //   zoom: expansionZoom,
                      // });
                    }}
                  >
                    <svg
                      className="w-[80px]"
                      viewBox="0 0 408 424"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M176 339.55V388.449C176 408.063 188.552 424 204 424C219.448 424 232 408.063 232 388.449V339.55C232 319.936 219.448 304 204 304C188.552 304 176 319.936 176 339.55Z"
                        fill="#d06338"
                      />
                      <path
                        d="M204 120C219.448 120 232 104.064 232 84.4495V35.5505C232 15.9364 219.448 0 204 0C188.552 0 176 15.9364 176 35.5505V84.4495C176 104.064 188.552 120 204 120Z"
                        fill="#d06338"
                      />
                      <path
                        d="M339.07 240H372.93C392.279 240 408 227.448 408 212C408 196.552 392.279 184 372.93 184H339.07C319.721 184 304 196.552 304 212C304 227.448 319.721 240 339.07 240Z"
                        fill="#d06338"
                      />
                      <path
                        d="M35.0698 240H68.9302C88.279 240 104 227.448 104 212C104 196.552 88.279 184 68.9302 184H35.0698C15.721 184 0 196.552 0 212C0 227.448 15.721 240 35.0698 240Z"
                        fill="#d06338"
                      />
                      <path
                        d="M120.787 293.289C127.227 300.342 135.94 304 144.778 304C152.986 304 161.068 300.865 167.507 294.595L203.874 258.934L240.24 294.595C246.679 300.865 254.761 304 262.968 304C271.681 304 280.394 300.473 286.96 293.289C299.461 279.573 298.956 258.019 285.697 245.087L251.857 211.908L285.571 178.859C298.83 165.927 299.335 144.243 286.834 130.658C274.333 116.942 253.372 116.419 240.24 129.352L204 164.882L167.76 129.352C154.501 116.419 133.667 116.942 121.166 130.658C108.665 144.374 109.17 165.927 122.429 178.859L156.143 211.908L122.302 245.087C109.044 258.019 108.539 279.703 121.04 293.289H120.787Z"
                        fill="#d06338"
                      />
                    </svg>
                    {/* <span className="opacity-0">{pointCount}</span> */}
                  </div>
                </Marker>
              );
            }

            const marker = cluster.properties;
            const lat =
              marker?.locationDetails?.location?.lat || marker?.location?.lat;
            const lon =
              marker?.locationDetails?.location?.lon || marker?.location?.lon;
            if (!lon || !lat) return null;
            const image = marker?.mapImage || marker?.featuredImage || {};
            const calculateOffset = () => {
              // if (marker?.city === "Milwaukee" && zoom > 10) return [50, 20];
              // if (marker?.city === "Janesville" && zoom > 10) return [0, 30];
              // if (marker?.city === "Fond du Lac" && zoom > 10) return [0, 10];
              // if (marker?.city === "New Berlin" && zoom > 10) return [30, 50];
              if (marker?.title === "St. Anne's" && zoom > 10) return [10, 10];
              return [0, 20];
            };
            // console.log(imageWidth, zoom);
            return (
              <Marker
                key={marker.title}
                longitude={lon}
                latitude={lat}
                offset={calculateOffset()}
                anchor="bottom-left"
              >
                <div
                  className={`cursor-pointer flex flex-col gap-0 items-start overflow-hidden pointer-events-none ${styles.marker}`}
                  style={{ width: `${imageWidth + 150}px` }}
                >
                  <If condition={zoom < 11}>
                    <button
                      type="button"
                      onClick={(e) => zoomToSelectedLoc(e, marker, index)}
                      className="pointer-events-auto"
                    >
                      <svg
                        className="w-[50px]"
                        viewBox="0 0 408 424"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M176 339.55V388.449C176 408.063 188.552 424 204 424C219.448 424 232 408.063 232 388.449V339.55C232 319.936 219.448 304 204 304C188.552 304 176 319.936 176 339.55Z"
                          fill="#0c2340"
                        />
                        <path
                          d="M204 120C219.448 120 232 104.064 232 84.4495V35.5505C232 15.9364 219.448 0 204 0C188.552 0 176 15.9364 176 35.5505V84.4495C176 104.064 188.552 120 204 120Z"
                          fill="#0c2340"
                        />
                        <path
                          d="M339.07 240H372.93C392.279 240 408 227.448 408 212C408 196.552 392.279 184 372.93 184H339.07C319.721 184 304 196.552 304 212C304 227.448 319.721 240 339.07 240Z"
                          fill="#0c2340"
                        />
                        <path
                          d="M35.0698 240H68.9302C88.279 240 104 227.448 104 212C104 196.552 88.279 184 68.9302 184H35.0698C15.721 184 0 196.552 0 212C0 227.448 15.721 240 35.0698 240Z"
                          fill="#0c2340"
                        />
                        <path
                          d="M120.787 293.289C127.227 300.342 135.94 304 144.778 304C152.986 304 161.068 300.865 167.507 294.595L203.874 258.934L240.24 294.595C246.679 300.865 254.761 304 262.968 304C271.681 304 280.394 300.473 286.96 293.289C299.461 279.573 298.956 258.019 285.697 245.087L251.857 211.908L285.571 178.859C298.83 165.927 299.335 144.243 286.834 130.658C274.333 116.942 253.372 116.419 240.24 129.352L204 164.882L167.76 129.352C154.501 116.419 133.667 116.942 121.166 130.658C108.665 144.374 109.17 165.927 122.429 178.859L156.143 211.908L122.302 245.087C109.044 258.019 108.539 279.703 121.04 293.289H120.787Z"
                          fill="#0c2340"
                        />
                      </svg>
                    </button>
                    <div
                      className={`${zoom > 10 ? "opacity-100" : "opacity-0"} ${
                        styles.text
                      } transition-all duration-200 text-white bg-teal rounded-lg w-full pointer-events-none absolute z-30 top-[-10px] left-[-10px]`}
                    >
                      <img
                        src={`${image.url}?w=300`}
                        alt={image.description}
                        className="object-cover aspect-video w-full rounded-lg pointer-events-none"
                      />
                      <If
                        condition={
                          (marker?.locationDetails?.city || marker.city) &&
                          (marker?.locationDetails?.state || marker.state)
                        }
                      >
                        <p className="text-[0.8rem] font-normal uppercase leading-none text-dark-blue text-left my-1 pointer-events-none pt-2 px-2">
                          {marker?.locationDetails?.city || marker.city},{" "}
                          {marker?.locationDetails?.state || marker.state}
                        </p>
                      </If>
                      <p className="text-[1.1rem] font-bold uppercase text-white text-left leading-4 pointer-events-none pb-4 px-2">
                        {marker.title}
                      </p>
                    </div>
                  </If>
                  <If condition={zoom >= 11}>
                    <div className=" bg-teal rounded-lg w-full">
                      <button
                        type="button"
                        onClick={(e) => {
                          console.log("clicked");
                          zoomToSelectedLoc(e, marker, index);
                        }}
                        className={`cursor-pointer flex flex-col gap-0 items-start overflow-hidden  pointer-events-auto ${styles.marker}`}
                      >
                        <img
                          src={`${image.url}?w=300`}
                          alt={image.description}
                          className="object-cover aspect-video w-full rounded-lg"
                          style={{ width: `${imageWidth + 150}px` }}
                        />
                      </button>
                      <div
                        className={`${
                          zoom > 10 ? "opacity-100" : "opacity-0"
                        } ${
                          styles.text
                        } transition-all duration-200 bg-teal rounded-lg px-2 py-2 text-white w-full pointer-events-none`}
                      >
                        <If
                          condition={
                            (marker?.locationDetails?.city || marker.city) &&
                            (marker?.locationDetails?.state || marker.state)
                          }
                        >
                          <p
                            className="text-[0.8rem] font-normal uppercase leading-none text-dark-blue text-left max-w-[150px] mt-1"
                            style={{ width: `${imageWidth}px` }}
                          >
                            {marker?.locationDetails?.city || marker.city},{" "}
                            {marker?.locationDetails?.state || marker.state}
                          </p>
                        </If>

                        <p
                          className="text-[1rem] font-bold uppercase text-white text-left max-w-[150px] leading-4"
                          style={{ width: `${imageWidth + 50}px` }}
                        >
                          {marker.title}
                        </p>
                      </div>
                    </div>
                  </If>
                </div>
              </Marker>
            );
          })}
        </Map>
        <If condition={showOverlay}>
          <div className="absolute top-0 left-0 right-0 bottom-0 z-[50] slide-in delay-1000">
            <button
              onClick={() => {
                setShowOverlay(false);
                // mapRef.current.flyTo({
                //   center: [-89.44264292318853, 43.065402754145495],
                //   zoom: 7,
                // });
              }}
              className="absolute top-2 right-2 text-white border-2 border-white px-6 py-2 rounded-full text-base test-orange"
            >
              Close
            </button>
            <div
              className={`grid grid-cols-12 gap-0 h-full ${
                styles["location-overlay"]
              }  ${
                selectedMarker?.location?.theme === "Green"
                  ? "bg-green"
                  : "bg-dark-blue"
              }`}
            >
              <div className="col-span-12 md:col-span-6 rounded overflow-hidden h-full">
                <img
                  src={
                    selectedMarker?.location?.mapImage?.url ||
                    selectedMarker?.location?.featuredImage?.url
                  }
                  alt={
                    selectedMarker?.location?.mapImage?.description ||
                    selectedMarker?.location?.featuredImage?.description
                  }
                  className="rounded-lg object-cover w-full h-full"
                />
              </div>
              <div
                className={`col-span-12 md:col-span-6 stack gap-14 h-full overflow-auto  ${getTheme(
                  selectedMarker?.location?.theme
                )} px-8 pt-18 pb-20`}
                data-lenis-prevent
              >
                <div className="stack gap-4">
                  <If
                    condition={
                      (selectedMarker?.location?.city ||
                        selectedMarker?.location?.locationDetails?.city) &&
                      (selectedMarker?.location?.state ||
                        selectedMarker?.location?.locationDetails?.state)
                    }
                  >
                    <p className="text-[1rem] font-normal uppercase leading-none">
                      {selectedMarker?.location?.city ||
                        selectedMarker?.location? .locationDetails?.city}
                      ,{" "}
                      {selectedMarker?.location?.state ||
                        selectedMarker?.location?.locationDetails?.state}
                    </p>
                  </If>
                  <Link
                    href={`/communities/${selectedMarker?.location?.slug}`}
                    className="text-white hover:text-orange transition-all duration-300"
                  >
                    <h2 className="text-card-title leading-none  hover:text-orange transition-all duration-300">
                      {selectedMarker?.location?.title}
                    </h2>
                  </Link>
                </div>
                <div className="stack gap-8">
                  <If condition={selectedMarker?.location?.mark !== ""}>
                    <div
                      className="w-[100px] mx-auto mix-blend-soft-light"
                      dangerouslySetInnerHTML={{
                        __html: selectedMarker?.location?.mark,
                      }}
                    />
                  </If>
                  <If condition={selectedMarker?.location?.subtitle !== ""}>
                    <p className=" text-white text-[1.25rem]">
                      {selectedMarker?.location?.subtitle}
                    </p>
                  </If>
                </div>
                <If condition={selectedMarker?.location?.isExternal}>
                  <Button
                    link={selectedMarker?.location?.externalLink}
                    target="_blank"
                  >
                    Visit Website
                  </Button>
                </If>
                <If condition={!selectedMarker?.location?.isExternal}>
                  <Button
                    className="w-full"
                    link={`/communities/${selectedMarker?.location?.slug}`}
                  >
                    Learn More
                  </Button>
                </If>
              </div>
            </div>
          </div>
        </If>
      </div>
    </AnimateIn>
  );
};

export default CommunityMap;
